<template>
  <div>
    <section class="welcome welcome__confirmedpay">
    </section>
    <div class="section container">
      <div class="welcome__inner confirmed__pay">
        <div class="form__container form__confirm__pay">

          <!--HTML-элемент, в котором будет отображаться платежная форма-->
          <div id="payment-form" ref="widgetForm"></div>

          <router-link :to="{ name: 'home' }" class="confirmed__pay__link">Вернуться на главную</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmPay',
  beforeRouteLeave (to, from, next) {
    this.$store.state.testDonateResponse = {}
    next()
  },
  mounted () {
    if (!this.$store.state.testDonateResponse?.conformation_token || !this.$store.state.testDonateResponse?.return_url) {
      this.$router.push({ name: 'home' })
      return 0
    }

    // const scriptEl = document.createElement('script')
    // scriptEl.setAttribute('src', 'https://yookassa.ru/checkout-widget/v1/checkout-widget.js')
    // this.$refs.widgetForm.parentElement.appendChild(scriptEl)

    //Инициализация виджета. Все параметры обязательные.
    const checkout = new window.YooMoneyCheckoutWidget({
      confirmation_token: this.$store.state.testDonateResponse.conformation_token, //Токен, который перед проведением оплаты нужно получить от ЮKassa
      return_url: this.$store.state.testDonateResponse.return_url, //Ссылка на страницу завершения оплаты
      error_callback: function (error) {
        console.error(error)
        //Обработка ошибок инициализации
      }
    });
    //Отображение платежной формы в контейнере
    checkout.render('payment-form')
      //После отображения платежной формы метод render возвращает Promise (можно не использовать).
      .then(() => {
        //Код, который нужно выполнить после отображения платежной формы.
      });
  }
}
</script>
